<template>
  <!-- profile info  -->
  <section id="profile-info">
    <b-row>
      <!-- about suggested page and twitter feed -->
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <profile-about :master-id="userData.instansi_id" />
        <profile-side-menu :data-menu="currentPath" />
      </b-col>
      <!--/ about suggested page and twitter feed -->

      <!-- post -->
      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <b-card>
          <!-- search input -->
          <div class="d-flex justify-content-between mb-2">
            <h2>No. Surat: {{ clearanceData.letter_number }}</h2>
            <div>
              <template v-if="userData.group_id !== 10">
                <b-link
                  v-if="clearanceData.year_budget === 2023"
                  :to="{ name: 'kegiatan-baru', params : {id: clearance_id} }"
                  class="btn btn-info btn-sm"
                >
                  Tambah Kegiatan
                </b-link>
                <b-button
                  v-if="clearanceData.year_budget === 2025"
                  class="btn btn-info btn-sm"
                  @click.prevent="tambahKegiatan"
                >
                  Tambah Kegiatan
                </b-button>
              </template>
              <b-link
                v-if="userData.group_id === 100"
                :to="{ name: 'pengajuan-surat-ubah', params : {id: clearance_id} }"
                class="btn btn-outline-warning btn-sm ml-50"
              >
                Ubah Surat
              </b-link>
            </div>
          </div>

          <!-- search input -->
          <div class="custom-search d-flex justify-content-between">
            <div>
              <b-button
                v-if="clearanceData.document_uri"
                size="sm"
                variant="outline-info"
                class="mr-50"
                @click="viewFile(clearanceData.document_uri)"
              >
                View File Surat Permohonan
              </b-button>
              <b-button
                v-if="clearanceData.blueprint_uri"
                size="sm"
                variant="outline-info"
                @click="viewFile(clearanceData.blueprint_uri)"
              >
                View File Rencana Kegiatan
              </b-button>
            </div>
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'nama_kegiatan'"
              >
                <b-link
                  :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
                  class="item-review text-primary"
                >
                  {{ props.row.nama_kegiatan }}
                </b-link>
              </span>
              <span v-else-if="props.column.field === 'total_anggaran'">
                {{ Number(props.row.total_anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'status'">
                <b-badge
                  :variant="statusVariant(props.row.status).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.status).title }}
                </b-badge>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-if="props.row.status === 'Perlu Perbaikan'"
                  v-b-tooltip.hover
                  :to="{ name: 'kegiatan-review', params: { id: props.row.data_utama_id } }"
                  variant="warning"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Review Kegiatan"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="14"
                  />
                </b-button>
                <b-button
                  v-else
                  v-b-tooltip.hover
                  :to="{ name: 'kegiatan-detail', params: { id: props.row.data_utama_id } }"
                  variant="primary"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Detail Kegiatan"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    size="14"
                  />
                </b-button>
                <b-button
                  v-if="yearBudget === 2023 && (props.row.status === 'Draft' || props.row.status === 'Perlu Perbaikan')"
                  v-b-tooltip.hover
                  :to="{ name: 'kegiatan-ubah', params: { id: props.row.data_utama_id } }"
                  variant="secondary"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Ubah / Lengkapi Kegiatan"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="14"
                  />
                </b-button>
                <b-button
                  v-if="yearBudget !== 2023 && (props.row.status === 'Draft' || props.row.status === 'Perlu Perbaikan')"
                  v-b-tooltip.hover
                  :to="{ name: 'kegiatan-ubah-item', params: { id: props.row.data_utama_id } }"
                  variant="secondary"
                  size="sm"
                  class="mr-25 mb-25"
                  title="Ubah / Lengkapi Kegiatan"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="14"
                  />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['15', '30', '60', '100']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-tambah-kegiatan"
      ref="modal-tambah-kegiatan"
      title="Pilih Kriteria Belanja"
      hide-footer
      centered
      scrollable
      size="md"
    >
      <b-card-text>
        <template v-if="yearBudget < 2025">
          <b-list-group>
            <b-list-group-item
              v-for="data in referenceData.ref_jenis_pengadaan"
              :key="data.jenis_pengadaan_id"
              class="d-flex justify-content-start align-items-center"
              :to="{ name: 'kegiatan-baru-item', params : {id: clearance_id, jenis: data.jenis_pengadaan_id} }"
            >
              Belanja {{ data.nama }}
            </b-list-group-item>
          </b-list-group>
        </template>

        <template v-else>
          <h5>{{ stepAnggaran }}</h5>
          <b-list-group>
            <b-list-group-item
              v-for="data in referenceData.ref_jenis_pengadaan"
              :key="data.jenis_pengadaan_id"
              class="d-flex justify-content-start align-items-center"
              :to="{ name: 'kegiatan-baru-krisna', params : {id: clearance_id, jenis: data.jenis_pengadaan_id} }"
            >
              Belanja {{ data.nama }}
            </b-list-group-item>
          </b-list-group>
        </template>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      cancel-variant="outline-secondary"
      ok-variant="warning"
      cancel-title="Batal"
      ok-title="Ya, Hapus!"
      modal-class="modal-warning"
      centered
      title="Attention!"
      @cancel="clearDeleteItem()"
      @ok="deleteItem()"
    >
      <b-card-text>
        Hapus Data Surat?
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BButton, BCardText, BRow, BCol, VBModal, BModal, BCard, BLink, BPagination, BFormGroup,
  BFormInput, BFormSelect, VBTooltip, BBadge, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import VuePdfApp from 'vue-pdf-app'

import stColors from '@/vars/statusColors'
import tableColumns from '@/vars/ippdColumns'

import ProfileAbout from './module/PengajuanAbout.vue'
import ProfileSideMenu from './module/PengajuanSideMenu.vue'

import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    BCardText,
    BButton,
    BModal,
    BCard,
    BLink,
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BListGroup,
    BListGroupItem,
    VuePdfApp,

    ProfileAbout,
    ProfileSideMenu,
  },
  setup() {
    const { statusColors } = stColors()
    const { oldColumns, newColumns } = tableColumns()

    const yearBudget = Number(localStorage.getItem('tahunAnggaran'))
    const columns = (yearBudget === 2023) ? oldColumns : newColumns

    return {
      yearBudget,
      statusColors,
      columns,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 10 && role < 99 && role > 110) {
      next('/')
    } else {
      next()
    }
    if (!to.params.id) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      stepAnggaran: 'Krisna',
      currentPath: 'pengajuan',
      userData: getUserData(),
      clearance_id: '',
      clearanceData: '',
      fileDoc: '',
      pageLength: 30,
      rows: [],
      searchTerm: '',
      toDelData: '',
      toDelItem: '',
      referenceData: {
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_pic: [],
        ref_sumber_dana: [],
      },
      krisna: false,
    }
  },
  created() {
    this.clearance_id = this.$route.params.id
    this.$http.get('/clearance', {
      params: {
        token: localStorage.getItem('userToken'),
        clearance_id: this.clearance_id,
      },
    })
      .then(res => {
        this.clearanceData = res.data.data

        if (this.clearanceData.year_budget !== this.yearBudget) {
          this.$router.replace({ name: 'pengajuan' })
        }

        if (this.clearanceData.children.length !== 0) {
          this.rows = this.clearanceData.children
        }
      })
    this.getReference()
  },
  mounted() {
    if (process.env.VUE_APP_STEP === 'sakti') {
      this.stepAnggaran = 'Sakti'
    }
  },
  methods: {
    statusVariant(status) {
      if (this.statusColors[status]) {
        return this.statusColors[status]
      }
      return {
        title: status,
        class: 'light-success',
      }
    },
    viewFile(value) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${value}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    removeKonten(value, index) {
      this.toDelData = value
      this.toDelItem = index
      this.$refs['modal-warning'].show()
    },
    clearDeleteItem() {
      this.toDelData = ''
      this.toDelItem = ''
    },
    deleteItem() {
      this.$http.delete(`/clearances/programs/${this.toDelData}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(res => {
          this.rows.splice(this.toDelItem, 1)
          this.clearDeleteItem(res)
        })
    },
    tambahKegiatan() {
      this.$refs['modal-tambah-kegiatan'].show()
    },
    getReference() {
      this.$http.get('/clearance/core-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: this.yearBudget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
          }
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
